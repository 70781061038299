.inicialContant {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url(./images/fundo.jpg);
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
.logo {
    width: 120px;
    max-width: 80%;
    padding-top: 25px;
    padding-bottom: 25px;
}
.logoContant {
    display: flex;
    align-items: center;
    gap: 15px;
}
.inicialContantActions {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
}

.inicialContantActions button {
    padding: 15px;
    width: 300px;
}
@media (max-width: 600px) {
    .inicialContant {
        background-size: auto 100%;
        background-position: -60px;
    }
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    border-radius: 5px; /* 5px rounded corners */
    padding: 15px;
    margin: 5px;
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
