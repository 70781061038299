.container-background {
    display: "flex";
    flex: 1;
    background-image: url("./shape.png"), url("./bg.jpg");
    background-size: auto auto, cover;
    background-position: 50%, 50%;
    background-attachment: fixed;
}

.container {
    display: "flex";
    height: 100%;
    flex-direction: column;
    justify-content: "center";
    align-items: "center";
    flex: 1;
    margin: 0 auto;
}

.title {
    font-weight: "bold";
    font-family: "Signika";
    font-size: "35px";
    color: white;
}
