.rowTalhao {
    display: flex;
    transition: 0.2s;
    border-bottom: 1px solid #c9c9c9;
    align-items: center;
}

.rowTalhao:hover {
    display: flex;
    transition: 0.2s;
    background-color: #fbfbfb;
}
