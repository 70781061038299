.marca {
    background-color: green;
}

.marcaProxVisita {
    background-color: rgb(46, 95, 255);
}

.react-calendar{
    width: unset;
    max-width: 350px;
}