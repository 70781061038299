/* Transpõe tabela para exibir uma lista em uma coluna, em dispositivos mobile.
*/
@media only screen and (max-width: 420px) {
    .DataTable .MuiDataGrid-root {
        border: none;
        border-radius: 0;
        border-left: 1px solid rgba(224, 224, 224, 1);
    }

    .DataTable .MuiDataGrid-columnHeaders {
        display: none;
    }

    .DataTable .MuiDataGrid-virtualScroller {
        margin-top: 0 !important;
    }

    .DataTable .MuiDataGrid-virtualScrollerRenderZone {
        width: 100% !important;
    }

    .DataTable .MuiDataGrid-row {
        flex-direction: column;
        width: 100% !important;
        max-height: 100% !important;
        border-bottom: 1px solid rgba(224, 224, 224, 1);
        padding: 0.5rem 0 0.5rem 0;
    }

    .DataTable .MuiDataGrid-cell {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        max-width: 100% !important;
        min-height: 30px !important;
        border: none !important;
    }

    .DataTable .cellLabel {
        display: block;
    }

    .DataTable .cellLabel,
    .DataTable .cellContent {
        font-size: 100%;
    }
}
