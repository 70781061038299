.menuPrimario {
  color: #fff;
  
}
.menuSecundario {
  color: #fff;
  background-color: #272727;
}


